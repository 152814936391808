import _ from "lodash";
import Axios from "axios";
import Helper from "utils/Helper";

Axios.defaults.withCredentials = true;

const UserAPI = {
	/**
	 * @param {boolean} options.log - 紀錄login log
	 */
	GetUserInfo: async (body = {}) => {
		try {
			const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/getUserInfo`, body);
			return data;
		} catch (error) {
			console.log("error : ", error);
			Helper.Print("Error: Fail to Get User Info! ", error);
			throw error;
		}
	},
	/**
	 * @body {string} email
	 * @body {string} password
	 */
	Login: async (email, password) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/login`, {
			email,
			password,
		});
		return _.get(data, "user", {});
	},
	Logout: async () => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/logout`);
		return data;
	},
	/**
	 * @body {string} email
	 * @body {string} password
	 * @body {string} lineId
	 * @body {number} otp
	 */
	Register: async (body = {}) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/register`, body);
		return data;
	},

	ResetPassword: async (body) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/resetPassword`, body);
		return data;
	},

	GetEnterpriseList: async (lineId) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/enterprise/list`, {
			lineId,
		});
		return data;
	},
	/**
	 * @param {string} lineId
	 * @param {string} name
	 */
	CreateEnterprise: async (body) => {
		const { data = {} } = await Axios.post(
			`${process.env.REACT_APP_BASE_API_URL}/enterprise/createEnterprise`,
			body
		);
		return data;
	},
	/**
	 * @param {string} lineId
	 * @param {number} enterpriseId
	 * @param {string} name
	 */
	UpdateEnterprise: async (body) => {
		const { data = {} } = await Axios.post(
			`${process.env.REACT_APP_BASE_API_URL}/enterprise/updateEnterprise`,
			body
		);
		return data;
	},

	SendValidationEmail: async (email = "", reset = false) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/sendValidationEmail`, {
			email,
			reset,
		});
		return data;
	},

	GetCommentLocations: async (lineId) => {
		const { data = {} } = await Axios.post(
			`${process.env.REACT_APP_BASE_API_URL}/commonLocation/getCommonLocations`,
			{
				lineId,
			}
		);
		return data;
	},
	/*
		location { address, geo, placeId }
	*/
	CreateCommentLocation: async (lineId, name, location) => {
		const { data = {} } = await Axios.post(
			`${process.env.REACT_APP_BASE_API_URL}/commonLocation/createCommonLocation`,
			{
				lineId,
				name,
				location,
			}
		);
		return data;
	},

	DeleteCommentLocation: async (lineId, id) => {
		const { data = {} } = await Axios.post(
			`${process.env.REACT_APP_BASE_API_URL}/commonLocation/deleteCommonLocation`,
			{
				lineId,
				id,
			}
		);
		return data;
	},

	GetUserList: async (lineId, userType) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/getUserList`, {
			lineId,
			userType,
		});
		return _.get(data, "userList", []);
	},
	/**
	 * @param {string} lineId
	 * @param {UserType} userType
	 * @param {string} email
	 * @param {string} username
	 * @param {string} phone
	 *
	 * passenger
	 * @param {number} entId
	 * @param {string} departmentName
	 * @param {string} employeeId
	 *
	 * driver
	 * @param {number} capacity
	 * @param {json} carInfo
	 */
	CreateUser: async (body = {}) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/createUser`, body);
		return data;
	},
	/**
	 * @param {string} lineId
	 * @param {UserType} userType
	 * @param {number} userId
	 * @param {string} email
	 * @param {string} username
	 * @param {string} phone
	 *
	 * passenger
	 * @param {number} entId
	 * @param {string} departmentName
	 * @param {string} employeeId
	 *
	 * driver
	 * @param {number} capacity
	 * @param {json} carInfo
	 */
	UpdateUser: async (body = {}) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/updateUser`, body);
		return data;
	},
	/**
	 * @param {string} username
	 * @param {string} phone
	 */
	UpdateProfile: async (body = {}) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/updateProfile`, body);
		return data;
	},
	/**
	 * @param {string} lineId
	 */
	Binding: async (lineId) => {
		const { data = {} } = await Axios.post(`${process.env.REACT_APP_BASE_API_URL}/user/binding`, {
			lineId,
		});
		return data;
	},
};

export default UserAPI;

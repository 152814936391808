import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			thin: "#b6e2bf",
			xLight: "#91d49e",
			light: "#6dc57e",
			main: "#7368FF",
			dark: "#399848",
		},
		secondary: {
			light: "#3fa5ec",
			main: "#147bc2",
			dark: "#0d5485",
		},
		dark: {
			light: "#6b84ae",
			main: "#3c4a62",
			dark: "#353541",
		},
		error: {
			light: "#fc8f8f",
			main: "#fc4343",
			dark: "#7c2121",
		},
		light: {
			light: "#f9fbff",
			main: "#d5dff2",
			dark: "#b3c4e6",
		},
		grey: {
			light: "#dcddde",
			main: "#85888c",
			dark: "#4a4d4f",
		},
		gray: {
			[250]: "#E8E8E8",
		},
		white: "#fff",
		black: "#000",
	},
});

export default theme;

const palette = {
	// primary: {
	// 	thin: "#b6e2bf",
	// 	xLight: "#91d49e",
	// 	light: "#6dc57e",
	// 	main: "#48b75e",
	// 	dark: "#399848",
	// },
	secondary: {
		light: "#3FA5EC",
		main: "#147BC2",
		dark: "#0D5485",
		110: "#3CAAAA",
	},
	dark: {
		light: "#6B84AE",
		main: "#3C4A62",
		dark: "#353541",
	},
	error: {
		light: "#fc8f8f",
		main: "#fc4343",
		dark: "#7c2121",
	},
	light: {
		light: "#f9fbff",
		main: "#d5dff2",
		dark: "#b3c4e6",
	},
	grey: {
		xLight: "#F9F9F9",
		light: "#DCDDDE",
		main: "#85888C",
		dark: "#4A4D4F",
		mid: "#929292",
		bg: "#F5F5F5",
		60: "#F0F0F3",
		70: "#E4E4EA",
		80: "#CDCDD6",
		90: "#B3B3C1",
		100: "#9696A6",
		110: "#757587",
		120: "#5E5E6D",
		130: "#43434F",
		140: "#353540",
		150: "#222228",
	},
	text: {
		main: "#252525",
		light: "#777777",
		lighter: "#949494",
		lightest: "#C8C8C8",
		xLight: "#E8E8E8",
	},
	white: "#fff",
	black: "#000",
};

export default palette;

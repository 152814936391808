import _ from "lodash";
import { isDev } from "constants/environment";
import { parse } from "json2csv";

const func = (props) => props;

const Helper = {
	GetCurrentGeoLocation: () => {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				async (position) => {
					const lat = _.get(position, "coords.latitude", "");
					const lng = _.get(position, "coords.longitude", "");

					console.log("Latitude is :", lat);
					console.log("Longitude is :", lng);

					resolve({
						lat,
						lng,
					});
				},
				(error) => {
					console.log("getCurrentLocation : ", error);
					reject("存取位置資訊失敗，請確認權限是否開啟。");
				}
			);
		});
	},
	GetGeoLocation: (placeInfo = {}) => {
		if (_.isEmpty(placeInfo)) {
			return {};
		}

		let geo = {};
		// 確認機場geometry

		const { formatted_address = "", name = "" } = placeInfo;

		if (
			(formatted_address.includes("桃園機場") ||
				formatted_address.includes("航站南路") ||
				formatted_address.includes("航站北路") ||
				name.includes("桃园机场") ||
				name.includes("台湾桃园国际机场")) &&
			!name.includes("捷運") &&
			!name.includes("捷运")
		) {
			geo = { lat: 25.0772507, lng: 121.2322277 };
		} else if (
			formatted_address.includes("松山機場") ||
			formatted_address.includes("臺北國際航空站") ||
			name.includes("台北松山机场")
		) {
			geo = { lat: 25.0675417, lng: 121.5526936 };
		} else {
			geo = {
				lat: placeInfo.geometry.location.lat,
				lng: placeInfo.geometry.location.lng,
			};
		}
		return geo;
	},
	FilterAddress: (input = "") => {
		if (!input) {
			return "";
		}

		if (input.includes("大園區航站南路9號")) {
			return "桃園國際機場(TPE)";
		} else if (input.includes("松山區敦化北路340-9號")) {
			return "臺北國際航空站(TSA)";
		}

		return input;
	},
	/**
	 * @param {string} title
	 * @param {Array|Object|string} log
	 */
	Print: (title, log = []) => {
		if (isDev) {
			const logs = _.isArray(log) ? log : [log];
			console.group(`${title}`);
			logs.forEach((el) => console.log(el));
			console.groupEnd();
			console.log("\n");
		}
	},

	Wait: (milliseconds = 0, callback = func) =>
		new Promise((resolve, reject) => {
			if (milliseconds <= 0) {
				resolve(callback());
				return;
			}
			setTimeout(() => resolve(callback()), milliseconds);
		}),

	ValidateEmailForamt: (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	},
	ValidatePasswordPormat: (password) => {
		const re = /(?=[A-Za-z0-9]+$)^(?=.{8,}).*$/;
		return re.test(String(password));
	},

	ValidatePhoneNumber(phoneNumber) {
		// 判斷是否都是數字或者第一個字符是"+"
		if (/^\d+$/.test(phoneNumber) || phoneNumber.charAt(0) === "+") {
			return true;
		} else {
			return false;
		}
	},

	ValidateTaiwanPhoneNumber(phoneNumber) {
		const regex = /^(09\d{8})$|^(8869\d{8})$|^(\+8869\d{8})$/;
		return regex.test(phoneNumber);
	},

	CleanString: (input = null) => {
		if (!input) {
			return input;
		}
		return input.replace(/[^\w\s]/g, "").replace(/\s/g, "");
	},

	IsValueEmpty: (value) => {
		return value === undefined || value === null || value === "" || (Array.isArray(value) && value.length === 0);
	},
	/**
	 * @param {geo} location.lat
	 * @param {geo} location.lng
	 * @param {string} location.address
	 */
	GetMapQueryUrl: (location = {}) => {
		const { geo = {}, address = "" } = location;
		const { lat = "", lng = "" } = geo;
		if (!lat || !lng) {
			return `https://maps.google.com?q=${address}`;
		}
		return `https://maps.google.com?q=${lat},${lng}`;
	},

	GetMapReservationUrl: (current = {}, origin = {}, dest = {}, stops = null) => {
		/* 目前位置 + 載客點 */
		let url = `https://www.google.com/maps/dir/${current.lat},${current.lng}/${origin.geo.lat},${origin.geo.lng}`;

		/* 加入停靠站 */
		if (stops !== null && stops.length) {
			for (let index = 0; index < stops.length; index++) {
				const { geo } = stops[index];
				url = `${url}/${geo.lat},${geo.lng}`;
			}
		}

		/* 加入終點 */
		url = `${url}/${dest.geo.lat},${dest.geo.lng}`;

		/* 現在位置 -> 載客點 -> 停靠點 -> 目的地 */
		return url;
	},

	/**
	 * 下載CSV
	 * @param {array} data
	 * @param {array} fields
	 * @param {string} fileName
	 * @param {function} callback
	 */
	DownloadCsv: (data, fields, fileName, callback = func) => {
		try {
			const csv = parse(data, { fields });
			const BOM = "\uFEFF";
			const csvContent = BOM + csv;

			const csvData = new Blob([csvContent], {
				type: "text/csv;charset=utf-8;",
			});

			const a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(csvData, {
				type: "text/csv;charset=utf-8;",
			});
			a.download = `${fileName}.csv`;
			document.body.appendChild(a);
			a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
			document.body.removeChild(a);

			callback();
			return { error: null };
		} catch (error) {
			callback(error);
			return { error };
		}
	},

	ExcludeBy: (origin = [], target = [], key = null) => {
		for (let index = 0; index < target.length; index++) {
			const targetItem = target[index];
			const targetValue = _.get(targetItem, key);
			for (let index2 = 0; index2 < origin.length; index2++) {
				const originItem = origin[index2];
				const originValue = _.get(originItem, key);
				if (originValue === targetValue) {
					origin.splice(index2, 1);
					break;
				}
			}
		}
		return origin;
	},

	GetLocationsFromLegs: (legs = []) => {
		const locations = [];
		for (let index = 0; index < legs.length; index++) {
			const leg = legs[index];

			/* 設定起點 */
			if (!locations.length) {
				locations.push({
					address: _.get(leg, "start_address"),
					geo: {
						lat: leg.start_location.lat(),
						lng: leg.start_location.lng(),
					},
				});
			}
			locations.push({
				address: _.get(leg, "end_address"),
				geo: {
					lat: leg.end_location.lat(),
					lng: leg.end_location.lng(),
				},
			});
		}
		return locations;
	},

	ToBool: (value) => {
		if (value == false || value === "false" || value === "FALSE") {
			return false;
		}

		if (value == true || value === "true" || value === "TRUE") {
			return true;
		}

		return false;
	},
};

export default Helper;

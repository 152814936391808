import { createTheme } from "@mui/material/styles";
import Palette from "./palette";

const theme = createTheme({
	palette: Palette,
	zIndex: {
		appBar: 1200,
		dialog: 1300,
	},
});

export default theme;

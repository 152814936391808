import UserAPI from "services/UserAPI";

export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOGOUT_USER = "LOGOUT_USER";

const func = () => {};

/**
 * get user info
 * @param {Object} params
 * @param {function} callback - (data, error)
 * @return {function(*)} - action
 */
export const getUserInfo =
	(params = {}, callback = func) =>
	(dispatch) => {
		UserAPI.GetUserInfo(params)
			.then((data) => {
				callback(data);
				return dispatch({
					type: FETCH_USER,
					payload: data,
				});
			})
			.catch((error) => {
				callback({}, error);
			});
	};

export const updateUser =
	(data, callback = func) =>
	(dispatch) => {
		callback(data, null);

		return dispatch({
			type: UPDATE_USER,
			payload: data,
		});
	};

export const logoutUser =
	(data, callback = func) =>
	(dispatch) => {
		callback(data, null);

		return dispatch({
			type: LOGOUT_USER,
			payload: data,
		});
	};

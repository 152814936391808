import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom"; // react-router v5
import { SnackbarProvider } from "notistack";
// utils
import { GAInitialize, GAPageView } from "utils/GA";
// Containers
const Login = lazy(() => import("components/navigation/Login"));
const Register = lazy(() => import("components/navigation/Register"));
const ResetPassword = lazy(() => import("components/navigation/ResetPassword"));
const Navigation = lazy(() => import("components/navigation/Navigation"));
const Terms = lazy(() => import("components/terms/Terms"));
const GoogleCallback = lazy(() => import("components/callback/GoogleCallback"));

const App = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		GAInitialize(process.env.REACT_APP_GA_ID);
	}, []);

	useEffect(() => {
		GAPageView();
	}, [pathname]);

	return (
		<div>
			<SnackbarProvider maxSnack={3}>
				<Suspense fallback={<div />}>
					<BrowserRouter>
						<Switch>
							<Route path="/terms" component={Terms} />
							<Route path="/login" component={Login} />
							<Route path="/register" component={Register} />
							<Route path="/resetPassword" component={ResetPassword} />
							{/* callback */}
							<Route path="/google/callback" component={GoogleCallback} />
							<Route path="/" component={Navigation} />
						</Switch>
					</BrowserRouter>
				</Suspense>
			</SnackbarProvider>
		</div>
	);
};

export default App;

import { isProd } from "constants/environment";
import ReactGA from "react-ga4";

export const Category = {
	Passenger: "Passenger",
	Driver: "Driver",
};

export const Action = {
	// reservation
	ClickReserveBtn: "ClickReserveBtn",
	ReserveSuccess: "ReserveSuccess",
	ReserveFailed: "ReserveFailed",
	CancelReservation: "CancelReservation",
};

export const GAInitialize = (trackingID) => {
	if (isProd) {
		console.log("trackingID", trackingID);
		ReactGA.initialize(trackingID);
	}
};

export const GAPageView = () => {
	if (isProd) {
		ReactGA.send("pageview");
	}
};

export const GASetUserId = (userId) => {
	if (isProd) {
		ReactGA.set({ user_id: userId });
	}
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 */
export const GAEvent = (body = {}) => {
	const { category, action, label, value } = body;
	if (isProd) {
		ReactGA.event({
			category,
			action,
			label: `${label}`,
			value, // number
		});
	}
};

import _ from "lodash";
import LSKey from "constants/localStorageKey";
import { Language } from "constants/general";
import { CHANGE_LANG } from "actions/general";

// Languages
const storedLang = localStorage.getItem(LSKey.language);
const [browserLang] = _.split(navigator.language, /[-_]/); // language without region code
const [defaultLang = Language[0]] = _.filter(Language, (el) => {
	const { value } = el;
	return _.includes(value, browserLang);
});

const defaultState = {
	lang: storedLang || defaultLang.value,
};

const generalReducer = (state = defaultState, action) => {
	switch (action.type) {
		case CHANGE_LANG: {
			const language =
				_.find(Language, { value: action.payload }) || Language[0];
			const { value: lang = "en" } = language;
			localStorage.setItem(LSKey.language, lang);

			return {
				...state,
				lang,
			};
		}

		default: {
			return state;
		}
	}
};

export default generalReducer;

import _ from "lodash";
import { FETCH_USER, UPDATE_USER, LOGOUT_USER } from "actions/user";

const userReducer = (state = {}, action) => {
	switch (action.type) {
		case FETCH_USER: {
			if (!action.payload) return state;

			const { userId } = action.payload;
			localStorage.setItem("userId", userId);

			return action.payload;
		}

		case UPDATE_USER: {
			return {
				...state,
				...action.payload,
			};
		}

		case LOGOUT_USER: {
			return {};
		}

		default:
			return state;
	}
};

export default userReducer;

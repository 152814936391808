import _ from "lodash";

export const CHANGE_LANG = "CHANGE_LANG";

const func = () => {};

export const changeLang = (lang) => ({
	type: CHANGE_LANG,
	payload: lang,
});

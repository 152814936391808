import "theme/globalStyles.css";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { LiffProvider } from "react-liff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
// constants
import { Mode } from "constants/general";
import lightTheme from "theme/lightTheme";
import darkTheme from "theme/darkTheme";
/* Redux */
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "store/configureStore";

const store = configureStore({});

// import customTheme from "assets/customTheme";
// const theme = createTheme(customTheme);

const liffId = process.env.REACT_APP_LINE_ID;
const stubEnabled = false; // 防止顯示真實數據

function Root() {
	const [themeMode, setThemeMode] = useState(Mode.light);

	const toggleTheme = (newThemeMode) => {
		setThemeMode(newThemeMode);
	};

	return (
		<React.StrictMode>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<ThemeProvider theme={themeMode === Mode.light ? lightTheme : darkTheme}>
						<LiffProvider liffId={liffId} stubEnabled={stubEnabled}>
							<App />
						</LiffProvider>
					</ThemeProvider>
				</ConnectedRouter>
			</Provider>
		</React.StrictMode>
	);
}
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
